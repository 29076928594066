.carousel .slide img{
    width: 80% !important;
  }
  .carousel.carousel-slider{
    z-index: 1 !important;
  }
  .carousel .slide .legend {
    transition: all .5s ease-in-out;
    position: relative !important;
    bottom: 28px !important;
    left: 0% !important;
    margin-left: 0% !important;
    width: 100% !important;
    border-radius: 10px;
    background: none !important;
    color: #000 !important;
    padding: 10px;
    font-size: 12px;
    text-align: justify !important;
    opacity: 1 !important;
    transition: opacity .35s ease-in-out;
  }
  .carousel .control-dots{
    text-align: left !important;
  }
  .carousel .control-dots .dot{
    box-shadow: none !important;
    background:#0071F2 !important;
  }