#wdr-pivot-view #wdr-grid-view div .imgDiv{
    min-height: 40px !important;
}

#wdr-pivot-view #wdr-grid-view div .centerDiv .imgWrapper{
    width:28px;
    height:28px;
    border-radius:50%;
    overflow:hidden;
    margin:auto;
}

#wdr-pivot-view #wdr-grid-view div .centerDiv .imgWrapper img{
    max-width:100%;
    height:28px;
}

#wdr-pivot-view #wdr-grid-view div .centerDiv{
    text-align:center;
}

#wdr-pivot-view #wdr-grid-view div .viewDiv{
    color:#0071F2;
    display:flex;
    align-items:center;
    justify-content:center;
    cursor:pointer;
    height:100%;
}

#wdr-pivot-view #wdr-grid-view div .viewDiv svg{
    width:15px;
    height:15px;
    fill:#0071F2;
    margin-right:7px;
}

#wdr-pivot-view #wdr-grid-view div .labelStyle{
    display:flex;
    align-items:center;
    justify-content:center;
    height:100%;
}

.wdr-credits a {
    display: none;
}
