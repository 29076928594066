.topNav-backIcon {
    font-size: 25px !important;
    cursor: pointer;
}

.topNav-title {
    display: inline-block;
    padding-left: 11px;
    color: #101010 !important;
    margin: 0 !important;
    font-size: 15px !important;
    font-family: 'pc_semibold' !important;
}

.topNav-parent {
    padding: 10px 0px;
    /* border-bottom: 1px solid black; */
    display: grid;
    grid-template-columns: 40px 1fr 20px;
    align-items: center;
}

.topNav-divider {
    display: inline-block;
    width: 0.5px !important;
    height: 25px !important;
}

.topNav-divider-actions {
    margin: auto 0px auto 18px !important;
    margin-right: 0px !important;
}

.topNav-tabs-Parent {
    display: inline-block;
}

.topNav-top-Parent {
    display: grid !important;
    grid-template-columns: 270px 1fr 472px;
    height:56px;
    align-items: center !important;
}
.topNav-top-Parent1 {
    display: grid !important;
    grid-template-columns: 293px 1fr 472px;
    height:56px;
    align-items: center !important;
}

.topNav-tabs {
    cursor: pointer;
    display: inline-block;
    margin-right: 18px !important;
    font-family: 'pc_medium' !important;
    color: #6F6F6F;
    font-size: 14px !important;
}

.topNav-active {
    /* border-bottom: 2px solid #2A60BC; */
    color: #EC6A49;
    /* padding-bottom: 20px; */
    margin: 0;
    font-family: 'pc_medium' !important;
    font-size: 14px !important;
    position: relative;
}

.bottomDivider{
    width: 100%;
    background-color: #EC6A49;
    height: 2px;
    position: absolute;
    top: 36px;
    transition: all 0.5s;
}

.topNav-actions {
    float: right;
    margin: auto;
    margin-left: 10px;
    margin-right: 0px;
    border-radius: 8px !important;
    height: 40px;
}

.topNav-actions-empty {
    border: 1px solid #DEE5EC !important;
    /* background-color: unset !important; */
    color: #01205C !important;
    background-color: #DEE5EC !important;
    font-family: 'pc_medium' !important;
    font-size: 14px !important;
    min-width: 130px !important;
}

.topNav-actions-parent-padding {
    padding-top: 8px !important;
}

.topNav-actions-parent {
    display: flex;
    align-items: center !important ;
    flex-direction: row-reverse !important;
}

.topNav-upload-icon {
    font-size: 28px !important;
    margin: auto;
}

.up-parent>label {
    width: 100%;
    border: 1px dashed #2A60BC;
    color: #2A60BC;
    font-size: 14px;
    font-family:'pc_medium !important';
    padding: 10px;
    border-radius: 8px;
}
.up-parent>label>p {
    color: #2A60BC;
}

.up-parent {
    width: 338px;
    padding: 15px 10px;
}

.up-img {
    margin: 0 !important;
    background: unset !important;
    border-radius: unset !important;
    width: 35px !important;
    height: 35px !important;
}

.up-eyeimg {
    margin-top: 10px !important;
    margin-right: 10px !important;
    cursor: pointer;
}

.up-deleteimg {
    cursor: pointer;
}

.up-size {
    color: #B6B6B6 !important;
    font-size: 10px !important;
    font-family: 'pc_medium' !important;
}

.up-title {
    color:#000000 !important;
    font-size: 12px !important;
    margin: 0 !important;
    font-family: 'pc_medium' !important;
    max-width: 200px !important;
    word-break: break-all !important;
}

.up-list-parent {
    display: flex;
    align-items: center;
    padding: 10px;
}

.uploadIcon{
    width: 40px;
    height: 40px;
    border: 1px solid #E0E0E0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px 0px 0px auto;
    cursor: pointer;
}

.uploadIcon img{
    width: 16px;
    height: 16px;
}
.cancelBtn{
    font-family: 'pc_medium' !important;
    font-size: 14px !important;
    color:#6F6F6F !important;
}

.successBtn{
    font-family: 'pc_medium' !important;
    background-color: #EC6A49 !important;
    border-color: #EC6A49 !important;
    font-size: 14px !important;
}
.successBtn_{
    font-family: 'pc_medium' !important;
    background-color: #EC6A49 !important;
    border-color: #EC6A49 !important;
    font-size: 14px !important;
    min-width: 170px !important;
}
.docc-modal{
    z-index: 9999999 !important;
}
.documenthead {
    padding: 16px;
  }
  .pg-viewer-wrapper{
    margin: auto;
  }

  /* .docc-modal {
    width: 100% !important;
    height: 100% !important;
    background: #cacacaba !important;
  } */
  
  .openFile-parent{
    display: block;
    height:90% !important

  }
  .pg-viewer-wrapper{
    min-width: 52%;
    height: 90vh !important;
    overflow: scroll;
  }

  .pdf-viewer-container{
      text-align: center;
  }