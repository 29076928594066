.ref-confirm-btn {
    font-size: 14px !important;
    border-radius: 8px !important;
    border: none !important;
    padding: 10px 14px 10px 14px !important;
    letter-spacing: 0.9 !important;
    width: 100%;
    margin-top: 15px;
    background-color: white !important;
    color: black !important;
    border: 1px solid lightgray !important;
    font-family: 'pc_medium' !important;
}

.ref-confirm-btn-bgcolor {
    background-color: #2A60BC !important;
    color: white !important;
}

.ref-confirm-avr-parent {
    display: flex !important;
    margin-bottom: 12px !important;
}

.ref-confirm-avr {
    margin: auto !important;
    width: 116px !important;
    height: 116px !important;
    background-color: #EAF5FF !important;
    border: 8px solid #F5FAFF !important;
}

.ref-confirm-avr>img {
    width: 49px;
    height: 54px !important;
}