.no-padding {
    padding: 0 !important;
}

.no-padding-left {
    padding-left: 0 !important;
}

.no-padding-left .dateInput input {
    -webkit-padding-end: 0px;
}

.no-padding-right {
    padding-right: 0 !important;
}

.no-padding-top {
    padding-top: 0 !important;
}

.no-padding-bottom {
    padding-bottom: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.no-margin-left {
    margin-left: 0 !important;
}

.no-margin-right {
    margin-right: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.space-margin-left {
    margin-left: 10px;
}

.space-margin-right {
    margin-right: 10px;
}

.space-margin-top {
    margin-top: 10px;
}

.space-margin-bottom {
    margin-bottom: 10px;
}

.space-padding-left {
    padding-left: 10px;
}

.space-padding-right {
    padding-right: 10px;
}

.space-padding-top {
    padding-top: 10px;
}

.space-padding-bottom {
    padding-bottom: 10px;
}

.space15-padding-bottom {
    padding-bottom: 15px;
}

.space15-padding-top {
    padding-top: 15px;
}

.light-background-color {
    /* background: red */
    background: #F1F6FC
}

.queue-style>div>div {
    z-index: 9999999;
}

/* wdr-empty */
.wdr-toolbar-group-right, .wdr-sheet-header, .wdr-wrapper, .wdr-resize-handles, .wdr-sheet-selection-header, #wdr-sheet-headers, .wdr-sheet-selection-canvas {
    display: none !important;
}

#wdr-data-sheet, #wdr-rows-sheet {
    left: 0px !important;
    top: 30px !important
}

#wdr-cols-sheet {
    left: 0px !important;
}

.wdr-filter-header {
    top: 0 !important;
}

/* .wdr-sheet-canvas{
    left :0 !important;
} */

.wd-profile-name {
    font-size: 12px !important;
    font-weight: 700 !important;
    margin-right: 4px !important;
}

.wd-profile-age {
    margin-right: 6px !important;
    padding-right: 6px !important;
}
.wd-profile-sideLineage {
    margin-right: 6px !important;
    padding-right: 6px !important;
    border-right: 1px solid !important;
}

.wd-profile-parent{
    margin-bottom: 4px !important;
}

.wd-profile-mrn{
    margin-right: 10px !important;
}